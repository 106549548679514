export function setCookie(name, value, minute) {
    var expires = "";
    if (minute) {
      var date = new Date();
      expires =
        "; expires=" +
        new Date(date.setMinutes(date.getMinutes() + minute)).toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
  
  export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  
  export function eraseCookie(name) {
    document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 2025 00:00:01 GMT;";
  }
  