const API_URL = process.env.REACT_APP_API_URL;
const API_URLPublic=API_URL+"/processing/images/"
const Apis = {
  UserLoginAPI: `${API_URL}/api/root/login`,
  api_updatecridentials: `${API_URL}/api/root/update/credentials`,
  api_admins: `${API_URL}/api/admins`,
  api_updateadmin: `${API_URL}/api/admins/update/`,
  api_profiles: `${API_URL}/api/profiles`,
  api_updateprofile: `${API_URL}/api/profiles/update/`,
  api_planpacks: `${API_URL}/api/planpacks`,
  api_updateplanpack: `${API_URL}/api/planpacks/update/`,
  api_updateplanpackcategories: `${API_URL}/api/planpacks/categories/`,
  api_products: `${API_URL}/api/products`,
  api_products_PlanPack: `${API_URL}/api/products/planpack/info/`,
  api_updateproducts: `${API_URL}/api/planpacks/update/`,
  
  api_salesOfferts: `${API_URL}/api/sales/offers`,
  api_updateSalesOffert: `${API_URL}/api/sales/offers/update/`,
  api_Categories: `${API_URL}/api/categories`,
  api_updateCategories: `${API_URL}/api/categories/update/`,




  api_customers: `${API_URL}/api/customers`,
  api_updatecustomer: `${API_URL}/api/customers/update/`,

  ////////////////////////////////////Orders/subscription//////////////////////////



  api_orders: `${API_URL}/api/orders/`,
  api_updateorder: `${API_URL}/api/orders/update/`,
  api_orders_detail: `${API_URL}/api/ordersdetails/info/`,
  api_updateorder_detail: `${API_URL}/api/ordersdetails/`,
  


  
  api_subscriptions: `${API_URL}/api/subscriptions`,
  api_updatesubscription: `${API_URL}/api/subscriptions/update/`,





  //////////////////////////to -fix/////////////////////////////

  UserProfileAPI: `${API_URL}/api/auth/service/profile`,
  api_customerCompte: `${API_URL}/api/customers`,
  api_planPack: `${API_URL}/api/planpack`,
  api_Subscriptions: `${API_URL}/api/subscriptions`,
  getcustomers: `${API_URL}/api/customers/index`,
  addcustomers: `${API_URL}/api/customers/add`,
  putcustomers: `${API_URL}/api/customers/edit/`,
  api_getNewOrders: `${API_URL}/api/orders/index`,

};

export { API_URL, Apis,API_URLPublic };