import api from '../../Api/api';
import { Apis } from '../../Api/config';

const getPlanPack = async () => {
    try {
        const result = await api.get(Apis.api_planpacks);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const addPlanPack = async (status) => {
    try {
        const result = await api.post(Apis.api_planpacks, status);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const updatePlanPack = async (data) => {
    try {
        const result = await api.put(`${Apis.api_updateplanpack}${data.id}`, data);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const updatePlanPackCategorie = async (data,id) => {
    try {
        const result = await api.put(`${Apis.api_updateplanpackcategories}${id}`, data);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const PlanPackFun = {
    getPlanPack,
    addPlanPack,
    updatePlanPack,
    updatePlanPackCategorie
};

export default PlanPackFun;
