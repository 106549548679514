import api from '../../Api/api';
import { Apis } from '../../Api/config';

const getSalesOffert = async () => {
    try {
        const result = await api.get(Apis.api_salesOfferts);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const addSalesOffert = async (status) => {
    try {
        const result = await api.post(Apis.api_salesOfferts, status);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const updateSalesOffert = async (data) => {
    try {
        const result = await api.put(`${Apis.api_updateSalesOffert}${data.id}`, data);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};


const SalesOffertFun = {
    getSalesOffert,
    addSalesOffert,
    updateSalesOffert,
};

export default SalesOffertFun;
