import api from '../../Api/api';
import { Apis } from '../../Api/config';

const getProfiles = async () => {
    try {
        const result = await api.get(Apis.api_profiles);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const getProfileStatus = async (status) => {
    try {
        const result = await api.get(Apis.api_profiles+"/status/"+status);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};


const addProfiles = async (status) => {
    try {
        const result = await api.post(Apis.api_profiles, status);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const updateProfiles = async (data) => {
    try {
        const result = await api.put(`${Apis.api_updateprofile}${data.id}`, data);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};


const profileFun = {
    getProfiles,
    addProfiles,
    updateProfiles,
    getProfileStatus
};

export default profileFun;
