import api from '../../Api/api';
import { Apis } from '../../Api/config';

const getCustomers = async () => {
    try {
        const result = await api.get(Apis.api_customers);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const addCustomers = async (status) => {
    try {
        const result = await api.post(Apis.api_customers, status);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const updateCustomers = async (data) => {
    try {
        const result = await api.put(`${Apis.api_updatecustomer}${data.id}`, data);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const updateCridentials = async (data,id) => {
    try {
        const result = await api.put(Apis.api_updatecridentials+id, data);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const CustomerFun = {
    getCustomers,
    addCustomers,
    updateCustomers,
    updateCridentials
};

export default CustomerFun;
