import api from '../../Api/api';
import { Apis } from '../../Api/config';

const getAdmins = async () => {
    try {
        const result = await api.get(Apis.api_admins);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const addAdmins = async (status) => {
    try {
        const result = await api.post(Apis.api_admins, status);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const updateAdmins = async (data) => {
    try {
        const result = await api.put(`${Apis.api_updateadmin}${data.id}`, data);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const updateCridentials = async (data) => {
    try {
        const result = await api.put(Apis.api_updatecridentials, data);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const AdminFun = {
    getAdmins,
    addAdmins,
    updateAdmins,
    updateCridentials
};

export default AdminFun;
