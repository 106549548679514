import api from '../../Api/api';
import { Apis } from '../../Api/config';

const getCategories = async () => {
    try {
        const result = await api.get(Apis.api_Categories);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const addCategories = async (status) => {
    try {
        const result = await api.post(Apis.api_Categories, status);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const updateCategories = async (data) => {
    try {
        const result = await api.put(`${Apis.api_updateCategories}${data.id}`, data);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};


const CategorieFun = {
    getCategories,
    addCategories,
    updateCategories,
};

export default CategorieFun;
