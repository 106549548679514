import api from '../../Api/api';
import { Apis } from '../../Api/config';

const getProduct = async () => {
    try {
        const result = await api.get(Apis.api_products);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const addProduct = async (status) => {
    try {
        const result = await api.post(Apis.api_products, status);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const updateProduct = async (data) => {
    try {
        const result = await api.put(`${Apis.api_updateproducts}${data.id}`, data);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const getproducts_PlanPack = async (id) => {
    try {
        const result = await api.get(`${Apis.api_products_PlanPack}${id}`);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};



const ProductFun = {
    getProduct,
    addProduct,
    updateProduct,
    getproducts_PlanPack
};

export default ProductFun;
