import api from '../Api/api';
import { Apis } from '../Api/config';

const getOrders = async () => {
    try {
        const result = await api.get(Apis.api_orders);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const addOrders = async (status) => {
    try {
        const result = await api.post(Apis.api_orders, status);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const updateOrders = async (data) => {
    try {
        const result = await api.put(`${Apis.api_updateorder}${data.id}`, data);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};


const OrderFun = {
    getOrders,
    addOrders,
    updateOrders,
};

export default OrderFun;
