import api from '../Api/api';
import { Apis } from '../Api/config';

const getSubscriptions = async () => {
    try {
        const result = await api.get(Apis.api_subscriptions);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const addSubscriptions = async (status) => {
    try {
        const result = await api.post(Apis.api_subscriptions, status);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const updateSubscriptions = async (data) => {
    try {
        const result = await api.put(`${Apis.api_updatesubscription}${data.id}`, data);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const updateCridentials = async (data,id) => {
    try {
        const result = await api.put(Apis.api_updatecridentials+id, data);
        return result.data.error ? null : result.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const SubscriptionFun = {
    getSubscriptions,
    addSubscriptions,
    updateSubscriptions,
    updateCridentials
};

export default SubscriptionFun;
